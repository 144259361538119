import React from 'react';
import ABOUTUSpng from '../../../assets/about/about-us.png';
import ABOUTUSwebp from '../../../assets/about/about-us.webp';

import Image from '../../../Components/Image';

import styles from './About.module.css';

const AboutUsImage = () => {
  return (
    <div className={styles.imageContainer}>
      <Image webpImage={ABOUTUSwebp} src={ABOUTUSpng} alt="ABOUT US" />
      <div className={styles.pinkShade} />
    </div>
  );
};
export default AboutUsImage;
