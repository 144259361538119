import React from 'react';
import Descriptor from '../../../Components/Descriptor';
import AboutUsImage from './AboutUsImage';
import styles from './About.module.css';

const description =
  "Alien Brains Private Limited headquartered in Kolkata was incorprated on 18th September 2017. We are both a product and service based company.We are a young brand with a notion for perfection. Our team dedicated, motivated to create magic wherever they work! The group operates two brands under our banner - Alien Brains Education & Sociana.Alien Brains Education not only gives you an environment for learning but also creates a path for their future. Sociana is one of the world's first of solution where one can Monitor, Manage & Organize all events from a centralized platform.";

export default function About() {
  return (
    <div className={styles.parentContainer} id="aboutus">
      <div className={styles.childContainer}>
        <div className={styles.leftContainer}>
          <AboutUsImage />
        </div>
        <div className={styles.rightContainer}>
          <Descriptor
            maxDescriptionCharacters={342}
            description={description}
            descriptorAlignment={styles.descriptorAlignment}
            title="ABOUT US"
            knowMoreText="READ MORE"
            disabled
          />
        </div>
      </div>
    </div>
  );
}
